// components/JoinList.js
import React, { useState } from 'react';
import { addEmailToDatabase } from '../firebase/firebase';
import './JoinList.css';

function JoinList() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      await addEmailToDatabase(email);
      setMessage('Success! You\'re on the list.');
      setEmail('');
    } catch (error) {
      setMessage('Error. Please try again.');
    }
    
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="join-form">
      <div className="input-group">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="example@email.com"
          required
          className="email-input"
        />
        <button 
          type="submit" 
          disabled={loading}
          className="submit-button"
        >
          Join Now
        </button>
      </div>
      {message && (
        <div className="message">
          {message}
        </div>
      )}
    </form>
  );
}

export default JoinList;