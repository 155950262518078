// App.js
import React from 'react';
import JoinList from './components/JoinList';
import './App.css';
import DeskImage from './assets/deskv2.svg';
import TwitterIcon from './assets/Twitter.svg';
import LinkedinIcon from './assets/Linkedin.svg';
import EmailIcon from './assets/Email.svg';
import { Analytics } from "@vercel/analytics/react"
import { Helmet, HelmetProvider } from 'react-helmet-async';



function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Helmet>
          <title>Clustor</title>
          <meta name="description" content="Clustor turns your ideas into applications. With a fully autonomous AI capable of designing, coding, executing, and self-correcting, Clustor makes software development accessible so you can bring your vision to life without writing a single line of code." />
          <meta name="keywords" content="AI-driven development,Fully autonomous agent,Idea-to-execution,AI-powered software creation,Automated coding,Prompt-based programming,Vision to code,No-code AI assistant,Intelligent automation,Developer productivity,Software generation,Hands-free coding,Innovation enabler,Effortless software building,End-to-end automation,Code at the speed of thought" />
        </Helmet>
        <div className="content-wrapper">
          <div className="text-content">
            {/* <h1 data-text="Clustor is coming">Clustor is coming</h1> */}
            <h1 className="title-container">
              <div className="stacked-text-wrapper">
                <div className="stacked-text">
                  <span className="text-1">Clustor</span>
                  <span className="text-2">Clustor</span>
                  <span className="text-3">Clustor</span>
                  <span className="text-4">Clustor</span>
                  <span className="text-5">Clustor</span>
                </div>
              </div>
              <span className="regular-text">
                <span className="colored-text">Clustor</span> is coming
              </span>
            </h1>
            <p className="description">
              Be the first to know when our product launches and get exclusive early access by joining our waitlist.
            </p>
            <JoinList />
            <div className="social-links">
              <a href="https://x.com/ClustorAI" className="social-link">
                <img src={TwitterIcon} alt="Twitter" className="social-icon" />
              </a>
              <a href="https://linkedin.com/in/ton-profil" className="social-link">
                <img src={LinkedinIcon} alt="LinkedIn" className="social-icon" />
              </a>
              <a href="mailto:contact@clustor.app" className="social-link">
                <img src={EmailIcon} alt="Email" className="social-icon" />
              </a>
            </div>
          </div>
          <div className="illustration">
            <img src={DeskImage} alt="Desk illustration" />
          </div>
        </div>
        <Analytics />
      </div>
    </HelmetProvider>
  );
}

export default App;